@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.upload-files-span {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: rgba(50, 50, 82, 1)
}

.upload-files-span-types {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin: 0;
}

.upload-files-span-dimensions {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}