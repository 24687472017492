@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.ql-toolbar.ql-snow {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-color: #ADADD6
}

.ql-container.ql-snow {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(98, 98, 138, 1);
    padding: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-color: #ADADD6
}

.input-label {
    @apply text-text-xs font-semibold text-neutral-400;
    text-align: left;
    text-transform: uppercase;
    font-family: 'Manrope';
}

.ql-tooltip {
    left: 0 !important;
    border-radius: 8px !important;
}

.ql-tooltip::before {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(98, 98, 138, 1);
    content: "Visita URL" !important;
}

.ql-tooltip::after {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: rgba(59, 122, 247, 1)
}

.ql-tooltip.ql-editing {
    left: 0 !important;
    border-radius: 8px !important;
    border: 1px solid #ADADD6 !important;
    box-shadow: 0px 2px 4px 0px #162A641F;
}

.ql-tooltip.ql-editing::before {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: rgb(115, 115, 139);
}

.ql-action::after {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: rgba(131, 131, 173, 1);
}

.ql-remove::before {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: rgba(255, 68, 61, 1);
}

.ql.preview a {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: rgba(50, 50, 82, 1);
}

.ql-tooltip.ql-editing input {
    border-radius: 5px !important;
    padding: 8px !important;
    border: 1px solid #ADADD6 !important;
    font-size: 14px !important;
    color: rgba(98, 98, 138, 1);
}

.ql-tooltip.ql-editing input::placeholder {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: rgba(173, 173, 214, 1) !important;
    text-decoration: none;
    padding: 0;
    font-family: 'Manrope';
    font-style: normal;
}

.ql-editor {
    background-color: white !important;
    border-radius: 8px;
}

.ql-editor a {
    color: rgba(38, 38, 69, 1) !important
}

.ql-header.ql-picker {
    display: none;
    padding: 0 !important;
}

.ql-editor.ql-blank[data-placeholder="Descrivere il contenuto dell'e-mail"]::before {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: rgba(173, 173, 214, 1) !important;
    text-decoration: none;
    padding: 0;
    font-family: 'Manrope';
    font-style: normal;
}

.ql-toolbar.ql-snow .ql-formats {
    margin: 0 !important;
}

.ql-snow .ql-color-picker,
.ql-snow .ql-icon-picker {
    margin-right: 15px;
    margin-left: 15px;
}

button.ql-link {
    margin-left: 15px !important;
}