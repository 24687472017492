.css-2d8n9v-control:hover,
.css-2d8n9v-control:focus:hover {
    outline: none;
    box-shadow: none;
    border-color: #CBCBEB !important;
}

.css-ojps74-control:hover,
.css-ojps74-control:focus:hover {
    outline: none;
    box-shadow: none;
    border-color: #CBCBEB !important;
}