.dropdown-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}

.dropdown-container {
    width: min-content;
    height: min-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 12px;
    gap: 4px;
    box-shadow: 0px 8px 16px 0px rgba(22, 42, 100, 0.12);
}