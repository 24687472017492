@keyframes dots {
    0% {
        opacity: 0;
    }

    33% {
        opacity: 1;
    }

    66% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.dots {
    display: inline-block;
    white-space: nowrap;
}

.dots span {
    opacity: 0;
    display: inline-block;
    animation: dots 1.5s steps(3) infinite;
    animation-timing-function: steps(3, end);
}

.dots span:nth-child(1) {
    animation-delay: 0s;
}

.dots span:nth-child(2) {
    animation-delay: 0.5s;
}

.dots span:nth-child(3) {
    animation-delay: 1s;
}