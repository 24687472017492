.react-datepicker__header,
.react-datepicker__header--custom {
    @apply bg-white border-0 p-0 flex flex-col h-[52px] gap-1;
}

.react-datepicker__header--time,
.react-datepicker__header--time--only {
    @apply hidden
}

.react-datepicker__time-list-item {
    @apply font-[Manrope] text-[12px] m-0 w-min h-6 rounded text-neutral-600 font-medium;
    transition: background 0.2s ease-in-out;
    line-height: 24px;
}

.react-datepicker__time-list-item--selected {
    @apply bg-brandPrimary-500 text-white !important;
}

.react-datepicker__day-name {
    @apply font-[Manrope] text-[12px] m-0 w-6 h-6 text-neutral-600 font-medium align-middle;
    line-height: 24px;
}

.react-datepicker__day {
    @apply font-[Manrope] text-[12px] m-0 w-6 h-6 rounded-full text-neutral-600 font-medium;
    transition: background 0.2s ease-in-out;
    line-height: 24px;
}

.react-datepicker__day--selected {
    line-height: 24px;
    @apply bg-brandPrimary-500 text-white !important;
}

.react-datepicker__day:hover {
    line-height: 24px;
    @apply bg-neutral-100 rounded-full !important;
}

.react-datepicker__day--selected:hover {
    line-height: 24px;
    @apply bg-brandPrimary-700 text-white rounded-full !important;
}

.react-datepicker__month {
    line-height: 24px;
    @apply m-0 p-0 flex flex-col gap-1;
}

.react-datepicker__month-container {
    line-height: 24px;
    @apply m-0 p-0 flex flex-col gap-1;
}

.react-datepicker__input-container {
    @apply flex flex-row-reverse items-center gap-1 p-0;
}

.react-datepicker__view-calendar-icon {
    @apply relative p-0
}

.react-datepicker__view-calendar-icon input {
    font-weight: 500;
    @apply relative p-0 text-neutral-600;
    @layer input-value;
}

.react-datepicker__view-calendar-icon input::placeholder {
    @apply text-label-sm text-neutral-400;
}