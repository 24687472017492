@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.checkbox-wrapper input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid rgba(173, 173, 214, 1);
  /* outline: 5px solid red; */
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0;
}

.checkbox-placeholder-small {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: rgba(50, 50, 82, 1);
  margin: 0;
  vertical-align: middle;
}

.checkbox-supporting-text-small {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: rgba(131, 131, 173, 1);
  margin: 0;
  vertical-align: middle;
}

.checkbox-placeholder-medium {
  font-family: Manrope;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: rgba(50, 50, 82, 1);
  margin: 0;
  vertical-align: middle;
}

.checkbox-supporting-text-medium {
  font-family: Manrope;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: rgba(131, 131, 173, 1);
  margin: 0;
  vertical-align: middle;
}

.checkbox-label-wrapper {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.checkbox-organizer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
}

.checkbox-wrapper input:checked {
  /* background-color: rgba(225, 225, 225, 1); */
  position: relative;
  border: 1px solid rgba(63, 72, 243, 1);
}

.checkbox-wrapper input:checked:focus {
  background-color: rgba(63, 72, 243, 1);
  position: relative;
  border: 1px solid rgba(63, 72, 243, 1) !important;
}

.checkbox-wrapper-medium input:checked::before {
  content: url('./icons/checkMedium.svg');
  color: rgba(63, 72, 243, 1);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.checkbox-wrapper-medium input:checked:focus::before {
  content: url('./icons/checkMediumFocus.svg');
  color: rgba(225, 225, 225, 1);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.checkbox-wrapper-small input:checked::before {
  content: url('./icons/checkSmall.svg');
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.checkbox-wrapper-small input:checked:focus::before {
  content: url('./icons/checkSmallFocus.svg');
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.checkbox-wrapper-medium input:indeterminate::before {
  content: url('./icons/indeterminateMedium.svg');
  color: rgba(63, 72, 243, 1);
  top: 9px;
  position: absolute;
}

.checkbox-wrapper-medium input:indeterminate:focus::before {
  content: url('./icons/indeterminateMediumFocus.svg');
  color: rgba(225, 225, 225, 1);
  top: 9px;
  position: absolute;
}

.checkbox-wrapper-small input:indeterminate::before {
  content: url('./icons/indeterminateSmall.svg');
  top: 7px;
  position: absolute;
}

.checkbox-wrapper-small input:indeterminate:focus::before {
  content: url('./icons/indetermnateSmallFocus.svg');
  top: 7px;
  position: absolute;
}

.checkbox-wrapper input[type="checkbox"]:disabled {
  opacity: 0.5;
}

.checkbox-wrapper input[type="checkbox"]:disabled+span {
  color: #c0c0c0;
}

.checkbox-wrapper input[type="checkbox"]:focus {
  box-shadow: 0 0 0 4px rgba(146, 92, 255, 0.16);
}