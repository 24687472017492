.input-value {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: rgba(98, 98, 138, 1);
    text-decoration: none;
    padding: 0;
}

.input-container input:disabled {
    color: rgba(116, 134, 170, 1);
    background-color: rgba(242, 242, 250, 1) !important;
}

.input-container input::placeholder {
    text-align: left;
    text-decoration: none;
}

.input-container {
    border: 1px solid;
    border-radius: 8px;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    padding: 0px 12px;
    gap: 8px;
    width: 100%;
}

.input-label {
    @apply text-text-xs font-semibold text-neutral-400;
    text-align: left;
    text-transform: uppercase;
}

.input-supporting-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}

[type="date"]::-webkit-inner-spin-button {
    display: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
    background: #fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png) 97% 50% no-repeat
}