@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.progress-bar-value {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: rgba(173, 173, 214, 1)
}
