.custom-table td,
.custom-table th {
    padding: 0 16px;
}

.custom-table th,
.custom-table td {
    white-space: nowrap;
    padding: 0px 6px;
}

.custom-table td:first-of-type,
.custom-table th:first-of-type {
    @apply pl-4
}

.custom-table td:last-of-type,
.custom-table th:last-of-type {
    @apply pr-4
}