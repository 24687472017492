@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

body {
  margin: 0;
  position: relative;
  font-family: 'Manrope';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Manrope';
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

input {
  --tw-ring-shadow: 0 0 #000 !important;
}

input {
  @apply ring-offset-0 ring-0
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: white;
  margin: 4px;
}

::-webkit-scrollbar-thumb {
  background: #E4E4F7;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #CBCBEB;
}

.crop-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.highlight {
  border-radius: 3px;
  animation: highlight 1000ms ease-out;
}

@keyframes highlight {
  0% {
    @apply bg-brandPrimary-200
  }

  100% {
    background-color: white;
  }
}